import { Atom } from ":mods";
import { Portal } from "solid-js/web";
import { $toast_group } from "../store";
import "../styles/toast-group.css";

export function ToastGroup() {
  return (
    <Portal>
      <Atom.Alert.ToastGroup alerts={$toast_group} />
    </Portal>
  );
}
